/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Startup Scripts
  $(document).ready(function() {

    var top_header = $(window).height() - $('header').outerHeight();
    // $('.hero').css('height', ($(window).height() - $('header').outerHeight()) + 'px'); // Set hero to fill page height
    $('#scroll-hero').click(function() {
      $('html,body').animate({scrollTop: $("#hero-bloc").height()}, 'slow');
    });

    /* 
    Removed at 24 Nov, 2016

    var is_home = $('.home'); 

    if(is_home.size() > 0) {


      var films_top = $('#bloc-films').offset().top,
          about_us_top = $('#bloc-9').offset().top,
          company_profile_top = $('#bloc-3').offset().top,
          news_top = $('#bloc-news').offset().top,
          submit_top = $('#bloc-11').offset().top;

      $(window).on('scroll', function(){
        if($(window).scrollTop() > top_header) {
          $('.home-fixed-header').addClass('active');
        } else {
          $('.home-fixed-header').removeClass('active');
        }  

        if($(window).scrollTop() > films_top && $(window).scrollTop() < news_top) {
          // control the menu
          $('#menu-primary_navigation a').removeClass('active');
          $('#menu-item-305 a').addClass('active');
        } else if($(window).scrollTop() > news_top && $(window).scrollTop() < about_us_top) {
          // control the menu
          $('#menu-primary_navigation a').removeClass('active');
          $('#menu-item-31 a').addClass('active');
        } else if($(window).scrollTop() > about_us_top && $(window).scrollTop() < company_profile_top) {
          $('#menu-primary_navigation a').removeClass('active');
          $('#menu-item-70 a').addClass('active');
        } else if($(window).scrollTop() > company_profile_top && $(window).scrollTop() < submit_top) {
          $('#menu-primary_navigation a').removeClass('active');
          $('#menu-item-26 a').addClass('active');
        } else if($(window).scrollTop() > submit_top) {
          $('#menu-primary_navigation a').removeClass('active');
          $('#menu-item-28 a').addClass('active');
        } else {
          $('#menu-primary_navigation a').removeClass('active');
          $('#menu-item-29 a').addClass('active');
        }
      });
    }
    */


    
  });

  // Animate when visable - initialized on load for Safari bug.
  $(window).load(function() {
    animateWhenVisable();  // Activate animation when visable
  });


  // Window resize 
  $(window).resize(function() {
    // $('.hero').css('height', ($(window).height() - $('header').outerHeight()) + 'px'); // Refresh hero height   
    // $('.hero').css('height', ($(window).height() - $('header').outerHeight()) + 'px'); // Refresh hero height   
  }); 

  // Scroll to target
  function scrollToTarget(D)
  {
    if(D == 1) // Top of page
    {
      D = 0;
    }
    else if(D == 2) // Bottom of page
    {
      D = $(document).height();
    }
    else // Specific Bloc
    {
      D = $(D).offset().top;
    }

    $('html,body').animate({scrollTop:D}, 'slow');
  }

  // Initial tooltips
  $(function()
  {
    $('[data-toggle="tooltip"]').tooltip()
  })


  // Animate when visable
  function animateWhenVisable()
  {
    $('.animated').removeClass('animated').addClass('hideMe'); // replace animated with hide
    
    inViewCheck(); // Initail check on page load
    
    $(window).scroll(function()
    {   
      inViewCheck(); // Check object visability on page scroll
      scrollToTopView(); // ScrollToTop button visability toggle
    });   
  };

  // Check if object is inView
  function inViewCheck()
  { 
    $($(".hideMe").get().reverse()).each(function(i)
    { 
      var target = jQuery(this);

      a = target.offset().top + target.height();
      b = $(window).scrollTop() + $(window).height() + 150;
      
      // console.log('a', a, 'b', b);
      if (a < b) 
      { 
        
        var objectClass = target.attr('class').replace('hideMe' , 'animated');
        
        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1) // If Firefox
        {
          target.css('visibility','hidden').removeAttr('class');
          setTimeout(function(){target.attr('class',objectClass).css('visibility','visable');},0.1);
        }
        else
        {
          // console.log(objectClass);
          // target.attr('class',objectClass)
          target.removeClass('hideMe');
          target.addClass('animated');
          target.addClass(target.attr('data-animated'));
        }     
      }
    });
  };

  // ScrollToTop button toggle
  function scrollToTopView()
  {
    if($(window).scrollTop() > $(window).height()/3)
    { 
      if(!$('.scrollToTop').hasClass('showScrollTop'))
      {
        $('.scrollToTop').addClass('showScrollTop');
      } 
    }
    else
    {
      $('.scrollToTop').removeClass('showScrollTop');
    }
  };

  $(function(){
    var has_single_post = $('.single-post, .category-news');

    if(has_single_post.size() > 0) {
      $(window).scrollTop($('main.main').offset().top);
    }
  })


  var _home_video_played = Cookies.get('_home_video_played');

  if ( typeof _home_video_played !== 'undefined') {
    $('#home-video').remove(); 
  } else {
    // video size 
    // w: 2048
    // h: 1080
    var video_oh = 1080,
        video_ow = 2048;

    var window_size = {
      width: $(window).width(),
      height: $(window).height()
    };

    // console.log(window.devicePixelRatio);
    var pixel_ratio = (typeof window.devicePixelRatio !== 'undefined') ? 1 : 1;
    var video_nh, video_nw;
      video_nh = window_size.height / pixel_ratio;
      video_nw = video_ow * (window_size.height / video_oh) / pixel_ratio;
    var margin_left = (video_nw - window_size.width) / 2
    $('#home-video').css({
      height: video_nh,
      width: video_nw,
      'z-index': 9999,
      'margin-left': margin_left * -1
    });

    var aud = document.getElementById("home-video");
    aud.onended = function() {
      // :TODO remove the top logo video block
      $('#home-video').animate({
        // top: 0,
        // left: 0,
        // width: '208px',
        // height: '130px',
        opacity: 0,

        'z-index': -1
      }, 1250, function() {
        $('#home-video').remove();
      });
    };
    Cookies.set('_home_video_played', '1');
  }

  $('.hero-player .play-button').magnificPopup({ 
    type:'iframe',
    callbacks: {
      elementParse: function(item) {
        // Function will fire for each target element
        // "item.el" is a target DOM element (if present)
        // "item.src" is a source that you may modify

        // console.log(item); // Do whatever you want with "item" object
      }
    }
  });

  // $('a').on('click', function(e){
  //   e.preventDefault();
  // })
  // var _hero_video = document.getElementById('hero-player');
  // $('.hero-player .play-button').on('click', function(e){
  //   e.preventDefault();
  //   // console.log('1');
  //   var that = $(_hero_video);
  //   // console.log('2');
  //   // that.removeClass('hide');
  //   that.show();
  //   // console.log('3');
  //   that.attr('src', that.attr('data-src'));
  //   // console.log('4');

  //   if (_hero_video.requestFullscreen) {
  //     _hero_video.requestFullscreen();
  //   } else if (_hero_video.mozRequestFullScreen) {
  //     _hero_video.mozRequestFullScreen();
  //   } else if (_hero_video.webkitRequestFullscreen) {
  //     _hero_video.webkitRequestFullscreen();
  //   }
  //   // console.log('5');

  // });

  // if (document.addEventListener) {
  //   document.addEventListener('webkitfullscreenchange', exitHandler, false);
  //   document.addEventListener('mozfullscreenchange', exitHandler, false);
  //   document.addEventListener('fullscreenchange', exitHandler, false);
  //   document.addEventListener('MSFullscreenChange', exitHandler, false);
  // }

  // function exitHandler() {
  //   console.log('entered handler')
  //   if (document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement !== null) {
  //     if($(_hero_video).hasClass('hide')) {
  //       $(_hero_video).removeClass('hide');
  //       $(_hero_video).attr('src', $(_hero_video).attr('data-src'));
  //     } else {
  //       $(_hero_video).addClass('hide');
  //       $(_hero_video).attr('src', '');
  //     }
  //   }
  // }

var owl = $('.owl-carousel').owlCarousel({
  loop:false,
  margin:0,
  nav:true,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
       items:1
    }
  },
  navText: ['<i class="fa fa-angle-left fa-3x">','<i class="fa fa-angle-right fa-3x">'],
  animateOut: true,
  animateOut: false
});

$(".dropdown-toggle").dropdown();

})(jQuery); // Fully reference jQuery after this point.

// for trailer handling
(function($){

$(document).ready(function() {
  $('.trailer').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,

    fixedContentPos: false
  });
});

  

})(jQuery);
